<template>
  <div class="introduce">
    <!-- 预览阅读劳务协议  -->
    <div id="demo"></div>
    <div class="btn">
        <van-button round type="info" size="large" class="up" @click="next">提交审核</van-button>
    </div>
    <div class="goBack" @click="back">
        <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import {
  Toast,
  Dialog
} from "vant";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css"
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState
} from 'vuex';
import {
  appHost
} from '@/utils/host'
import {parseUrl} from '@/utils/utils'
export default {
  name: '',
  data() {
    return {
      project_doctor_id: null,
      baseFileUrl: appHost,
      pdfh5: null,
      short_name: '',
    }
  },
  mounted() {
    this.short_name = this.basicInformation.projects[0].short_name
    this.project_doctor_id = this.$route.query.project_doctor_id
    this.init()
  },
  computed: {
    ...mapGetters(['project_id']),
    ...mapState(['basicInformation'])
  },
  methods: {
    ...mapMutations(['clearImgList','clearIdcardList']),
    init() {
      console.log("service_sign:",sessionStorage.getItem('service_sign'))
      console.log("baseFileUrl:",parseUrl(sessionStorage.getItem('service_sign'), this.baseFileUrl))
      // return
      this.pdfh5 = new Pdfh5("#demo", {
        pdfurl: parseUrl(sessionStorage.getItem('service_sign'), this.baseFileUrl),
      });
      //监听完成事件
      this.pdfh5.on("complete", function (status, msg, time) {
        console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
      })
    },
    next() {
      Dialog.alert({
        message: '提交审核后不可修改，是否确认提交审核？',
        title: '确定提交',
        showCancelButton: true,
        cancelButtonText: '我再想想',
        confirmButtonColor: '#4B99FF',
        confirmButtonText: '确定提交'
      }).then(() => {
        sessionStorage.removeItem('service_sign')
        if(this.short_name == 'bxzleq'){ //靶向治疗二期要移除知情同意书签名
          sessionStorage.removeItem('informed_consent_sign')
        }
        this.clearImgList()
        this.clearIdcardList()
        this.$router.replace({
            path: '/sub_project',
        })
      }).catch(() => {})
    },
    back() {
      if(this.short_name == 'bxzleq') { //靶向治疗二期返回页面有所不同
        this.$router.replace({
          path: '/read_agreement',
          query: {
            ...this.$route.query
          }
        })  
      }else{
        this.$router.replace({
          path: '/sign_service_agreement',
          query: {
            ...this.$route.query
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.introduce {
  height: 100%;
  width: 100%;

  .text {
    padding: 10px;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    margin-bottom: 70px;
  }
}
</style>